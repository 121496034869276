<template>
  <div>
    <ProductRevenues />
  </div>
</template>

<script>
import ProductRevenues from "../components/ReportCenter/ProductRevenues";
export default {
  components: {
    ProductRevenues,
  },
};
</script>

<style></style>
